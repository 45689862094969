import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Navigation, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';

import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALInstanceFactory, MSALInterceptorConfigFactory } from './app.module';
import { protectedResources } from './auth-config';
import { ApiService } from './services/api.service';
import { AppInsightService } from './services/appInsight.service';
import { CommonService } from './services/common.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: Subject<boolean> = this.loader.isLoading;
  firstLoad: boolean = true;
  constructor(private api: ApiService, private router: Router, public cs: CommonService, private loader: LoaderService, private broadcastService: MsalBroadcastService, private authService: MsalService) {
  }
  ngOnDestroy(): void {


  }


  ngOnInit(): void {

    this.setLoginDisplay();

    this.router.events.pipe(filter((e: any): e is RouterEvent => e instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
      if (environment.admin && this.firstLoad) {
        this.firstLoad = false
        // this.api.get('User/GetLoginUserDetails').subscribe(t => {
        this.api.get('AdminUser/GetAdminLoginUserDetails').subscribe(t => {
          this.cs.userName =t.userName
          this.cs.currentUser =t
          if (t.userRole == 'NTLStaff') {
            this.cs.isNtlStaff = true;
          }
          localStorage.setItem('userRole', t.userRole);
          this.cs.role.next(t.userRole)
        },
          (err: HttpErrorResponse) => {
            this.cs.openauthpopup('You are Unauthorized', 'warning', '/');
          })
      }
    });



    // this.broadcastService.msalSubject$.subscribe((e: any) => {
    //   if (e.eventType == 'msal:acquireTokenSuccess') {
    //     localStorage.setItem('token', e.payload.accessToken);
    //     this.api.get('User/GetLoginUserDetails').subscribe(t => {
    //       if (t.userRole == 'NTLStaff') {
    //         this.cs.isNtlStaff = true;
    //       }
    //       localStorage.setItem('userRole', t.userRole);
    //     })
    //   }

    // })
    let user = localStorage.getItem('userRole');

    if (user && user != null && user != '') {
      if (user == 'NTLStaff') {
        this.cs.isNtlStaff = true;
      }
      this.cs.role.next(user)
    } else {

      this.broadcastService.msalSubject$.subscribe((e: any) => {
        let user = localStorage.getItem('userRole');

        if (e.eventType == 'msal:acquireTokenSuccess' && user && user != null && user != '') {
          localStorage.setItem('token', e.payload.accessToken);
          // this.api.get('User/GetLoginUserDetails').subscribe(t => {
          //   if (t.userRole == 'NTLStaff') {
          //     this.cs.isNtlStaff = true;
          //   }
          //   localStorage.setItem('userRole', t.userRole);
          //   this.cs.role.next(t.userRole)
          // })
        }

      })
    }

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {

        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.cs.isloggedIn = this.authService.instance.getAllAccounts().length > 0;

  }
  title = 'sp-ntl-web-app';
}
