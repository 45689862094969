import { AppInsightService } from 'src/app/services/appInsight.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';

import { Observable, throwError, map } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    stopEditing(arg0: boolean) {
        throw new Error('Method not implemented.');
    }
    url = environment.apiurl + 'admin/';
    userurl = environment.apiurl;
    updateData = false;
    constructor(private http: HttpClient, private cs: CommonService, private broadcastService: MsalBroadcastService, private appInsight:AppInsightService) {

    }
    get(endpoint: string, params?: any): Observable<any> {
        // let headers = new HttpHeaders();
        // let header = headers.set('content-type', 'application/json')
        setTimeout(()=>{            
            // this.appInsight.logTrace(`fetching data for ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        },1000);
        return this.http.get(this.url + endpoint, { params: params })

    }

    csvDraftTitle(data?:any){
        // this.appInsight.logTrace(`Delete ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        return this.http.get(this.url + 'ManageDraftTitle/ManageDraftTitlesCsv?', {params:data, responseType: 'blob'});
    }

    csvLiveTitle(data?:any){
        // this.appInsight.logTrace(`Delete ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        return this.http.get(this.url + 'ManageLiveTitle/ManageLiveTitlesCsv?', {params:data, responseType: 'blob'});
    }

    logsDownload(data: any){
        // this.appInsight.logTrace(`Delete ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        return this.http.get(this.url + 'MassMail/GetlogViewDetails?', {params:data, responseType: 'blob'});
    }

    csvRequestHistory(data?:any){
        // this.appInsight.logTrace('download csv request history',{email:this.cs.currentUser['email']});

        return this.http.get(this.userurl + 'UserPortal/GetAllRequestHistoryCSV?', {params:data, responseType: 'blob'});
    }

    // getuserdetail(endpoint: string, token: any): Observable<any> {
    //     // let headers = new HttpHeaders();
    //     // headers.set('Authorization', `Bearer ${token}`)
    //     const headers = { 'Authorization': `Bearer ${token}` }
    //     return this.http.get(this.url + endpoint, { 'headers': headers })
    // }

    post(endpoint: string, data: any): Observable<any> {
        // this.appInsight.logTrace(`add ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});

        let headers = new HttpHeaders();
        let header = headers.set('content-type', 'application/json')
        //const headers = { 'content-type': 'application/json'}  
        return this.http.post(this.url + endpoint, data, { 'headers': headers })
    }

    userget(endpoint: string, params?: any): Observable<any> {
        let headers = new HttpHeaders();
        let header = headers.set('content-type', 'application/json')
        return this.http.get(this.userurl + endpoint, { params: params })

    }
    userpost(endpoint: string, data: any): Observable<any> {
        let headers = new HttpHeaders();
        let header = headers.set('content-type', 'application/json')

        //const headers = { 'content-type': 'application/json'}  
        return this.http.post(this.userurl + endpoint, data, { 'headers': headers })
    }

    userupdate(endpoint: string, data: any): Observable<any> {
        return this.http.put(this.userurl + endpoint, data)
        // .pipe()
    }
    userdelete(endpoint: string, params?: any): Observable<any> {

        return this.http.delete(this.userurl + endpoint, { params: params })
    }


    massMailPost(endpoint: string, data: any): Observable<any> {
        // this.appInsight.logTrace(`Edit ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        // let headers = new HttpHeaders(); 
        // let header = headers.set( 'content-type', 'application/json')  
        const headers = { 'content-type': 'application/json' }
        return this.http.post(this.url + endpoint, data, { 'headers': headers })
    }
    update(endpoint: string, data: any, params?: any): Observable<any> {
        // this.appInsight.logTrace(`Edit ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});

        return this.http.put(this.url + endpoint, data, { params: params })
        // .pipe()
    }
    delete(endpoint: string, params?: any): Observable<any> {
        // this.appInsight.logTrace(`Delete ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});

        return this.http.delete(this.url + endpoint, { params: params })
    }


    getExportErrorTitles(): Observable<any> {
        // this.appInsight.logTrace(`Delete ${this.appInsight.currentRoute}`,{email:this.cs.currentUser['email']});
        let importID = localStorage.getItem('ImportId');
        return this.http
            .get(this.url + `TitleImport/ExportErrorTitles?ImportId=${importID}`)
            .pipe(
                map((res: any) => {
                    // const blob = new Blob([res], {
                    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    // });

                    return res;
                })
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else if (error.status === 200) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            // console.error(
            //     `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(error.error);
    }
}
