import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  headingsArr: any = { vendors: 'Vendors', currency: 'Currency', s_d: 'School and Department', book_format: 'Book Format', s_d_subject: 'School and Department-Subject assignment' }
  private storageSub = new Subject();
  searchValue = new Subject();
  acknowledgementData: any
  loginDisplay = false;
  isNtlStaff = false;
  advanceSearch = new Subject();
  isloggedIn: boolean = false;
  role = new BehaviorSubject('');
  Authtoken: undefined;
  userName: string | any;
  currentUser: any;
  constructor(private authService: MsalService, private router: Router) { }


  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next('added');
  }

  removeItem(key: any) {
    localStorage.removeItem(key);
    this.storageSub.next('removed');
  }

  // clearAll() {
  //   localStorage.clear();
  //   this.storageSub.next('cleared');
  // }

  openPopUp(msg: any, type: any) {
    swal.fire(msg, '', type)

  }

  openauthpopup(msg: any, type: any, redirect?: any) {
    let a: any
    if (redirect) {
      a = document.createElement('a');
      a.setAttribute('href', redirect);
      a.innerText = 'Go Back Home'
    }
    if (!swal.isVisible()) {
      swal.fire(
        {
          title: msg,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Log In with different User',
          // denyButtonText: 'HomePage',
          // showDenyButton: true,
          allowOutsideClick: false,
          footer: redirect ? a : null,
          allowEscapeKey: false
        }
      ).then((value) => {

        if (value.isConfirmed == true) {

          if (redirect) {
            this.authService.logoutRedirect({
              postLogoutRedirectUri: redirect
            })
          } else {
            this.authService.logoutRedirect()
          }
        }
        else if (value.isDenied == true) {
          this.router.navigateByUrl('/')
        }
      })
    }
  }

}
