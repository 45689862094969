<div
  #tableInput
  class="list-content listing"
>
  <app-filters
    [ngStyle]="isMobile?{'flex':'0.9'}:{}"
    (searchValue)="fetchData($event)"
  ></app-filters>
  <div class="BookdataWrapper dynamicHeight">
    <mat-card
      class="cardLayout"
      *ngFor="let data of obs| async;let i = index"
    >
      <div class="imgSection">
        <img
          height="160px"
          onerror="this.onerror=null;this.src='assets/images/dummy.gif';"
          [src]="data.imgURL"
        >
      </div>
      <div class="dataSection">
        <span class="content">
          <mat-card-title>{{data.name}}</mat-card-title>
          <mat-card-subtitle>
            <span style="display: flex;">
              <span class="subtitles fadded">by :</span>
              <span class="authorshort">{{data.author}}</span>
            </span>
            <span
              class="fadded"
              style="padding: 0px 10px;"
            >
              |
            </span>
            <span *ngIf="data.bookFormatName&&data.bookFormatName!=''">
              <span class="subtitles fadded">type
                                :</span>
              {{data.bookFormatName}}
            </span>
            <span
              *ngIf="data.bookFormatName&&data.bookFormatName!=''"
              class="fadded"
              style="padding: 0px 10px;"
            >
              |
            </span>
            <span>
              <span class="subtitles fadded">year :</span>
              {{data.publicationYear}}
            </span>
          </mat-card-subtitle>
          <hr>







          <div class="textContent">
            <!-- <span *ngIf="data.bookFormatName == '<a href=>' "> -->







            <ng-container *ngIf="checklink(data.summary)">
              <p [ngClass]="data.showmore?'moreData':'shortData'">
                <a
                  target="_blank"
                  href="{{data.summary}}"
                >
                  {{data.summary}}
                </a>
              </p>
            </ng-container>
            <ng-container *ngIf="!checklink(data.summary)">
              <p [ngClass]="data.showmore?'moreData':'shortData'">{{data.summary}}</p>
            </ng-container>






            <p
              style="cursor: pointer;"
              (click)="seemore(i)"
              class="fadded"
            >
              {{data.showmore?'See Less':'See More'}}
            </p>





          </div>
        </span>
      </div>
      <div class="addButton">
        <button
          (click)="addlogin(data.id)"
          mat-stroked-button
          class="primaryButton"
          [ngClass]="data.added?'addedClass':''"
        >
          Add
          <mat-icon>add</mat-icon>
        </button>
      </div>








    </mat-card>
    <mat-paginator
      *ngIf="obs && obs._value.length"
      class="userPaginator"
      [length]="length"
      [pageSize]="PageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>







    <div
      *ngIf="obs && !obs._value.length"
      style="display: flex;align-self:center;justify-content: center;min-height: 50%;"
    >
      No Data to Show
    </div>
  </div>







</div>
