import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { PopupComponent } from '../popup/popup.component';
import { UserpopupComponent } from '../userpopup/userpopup.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  count = 0;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  datas: any[] = [];
  link:any

  constructor(
    public cs: CommonService,
    public dialog: MatDialog,
    public router: Router,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.checkData();
  }
  clearAll() {
    this.cs.removeItem('ntlCart');
    this.datas = this.getData();
    this.count = this.datas.length;
  }
  convertData(value: any) {
    // console.log(value)
    // const a_tag = /<a (.*)>/g;
    // let newString = value.replace(a_tag, '<a target="_blank" $1>');
    if (value.indexOf('http://') == 0 || value.indexOf('https://') == 0) {
      this.link =value.link(value)
      const a_tag = /<a (.*)>/g;
      let newString = this.link.replace(a_tag, '<a target="_blank" $1>');
      return newString
    }else{
      return value
    }

    //  else {
    //   newString = newString.replace('www', 'https://www');
    //   console.log("The link doesn't have http or https.");
    // }
  }
  checkData() {
    let data = this.getData();

    let params = { liveTitleIds: data.map((e: any) => e.id) };

    this.apiService
      .userpost('UserPortal/GetDuplicateRequest', params)
      .subscribe(
        (res: any[]) => {
          let response: any[] = res.map((e: any) => e.liveTitleId);
          this.datas = data.filter((e: any) => {
            if (!res.find((k) => k.liveTitleId == e.id)) {
              return e;
            }
          });
          let popupdata = data.filter((e: any) => {
            if (res.find((k) => k.liveTitleId == e.id)) {
              return (e['status'] = res.find((k) => k.liveTitleId == e.id)[
                'status'
              ]);
            }
          });
          if (popupdata && popupdata.length) {
            this.openDialog(popupdata);
          }
          this.cs.setItem('ntlCart', JSON.stringify(this.datas));
          this.count = this.datas.length;
        },
        (err: HttpErrorResponse) => {
          if (err.error.Message) {
            this.cs.openPopUp(err.error.Message, 'warning');
          } else {
            this.cs.openPopUp('Something Went Wtong!', 'warning');
          }
        },
        () => {}
      );
  }

  openDialog(data: any) {
    let config: MatDialogConfig = {
      panelClass: 'customPopup',
      width: '45%',
      maxHeight: '50vh',
      disableClose: true,
      data: data,
    };
    const dialogRef = this.dialog.open(PopupComponent, config);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getRemarkValue(e:any,i:number){
    let cart: any = localStorage.getItem('ntlCart');
    let data = JSON.parse(cart);
    if(this.datas[i]['remark']){
      this.datas[i]['remark']=!this.datas[i]['remark'];
      data[i]['remark']=data[i]['remark'];
    }else{
      data[i]['remark']=e.target.value ;
    }
    this.cs.setItem('ntlCart', JSON.stringify(data));
    // console.log("Remark Data : ",data);
  }

  getRemark(i:number){
    let cart: any = localStorage.getItem('ntlCart');
    let data = JSON.parse(cart);
    // console.log("Remak Data is : ",data[i]['remark'])
    return data[i]['remark']
  }

  makereserve(i: any) {
    let cart: any = localStorage.getItem('ntlCart');
    let data = JSON.parse(cart);

    if (this.datas[i]['reserveTitle']) {
      this.datas[i]['reserveTitle'] = !this.datas[i]['reserveTitle'];
      data[i]['reserveTitle'] = !data[i]['reserveTitle'];
    } else {
      this.datas[i]['reserveTitle'] = true;
      data[i]['reserveTitle'] = !data[i]['reserveTitle'];
    }

    this.cs.setItem('ntlCart', JSON.stringify(data));
  }
  submit() {
    let config: MatDialogConfig = {
      panelClass: 'userPopup',
      disableClose: true,
    };

    const dialogRef = this.dialog.open(UserpopupComponent, config);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result == true) {
        let cart: any = localStorage.getItem('ntlCart');
        let data: any[] = JSON.parse(cart);
        let dataArr: any[] = [];

        data.forEach((e) => {
          // console.log("Elemented data : ",e);
          let obj = {
            liveTitleId: e.id,
            reserveTitle: e.reserveTitle ? true : false,
            remark:e.remark
          };
          dataArr.push(obj);
        });

        this.apiService.userpost(`UserPortal/SubmitRequest`, dataArr).subscribe(
          (res: any) => {
            // this.cs.openPopUp(res.Message, 'success')
            this.router.navigateByUrl('/acknowledgement');
            localStorage.setItem('acknowledgement', JSON.stringify(res));

            this.cs.removeItem('ntlCart');
          },
          (error: HttpErrorResponse) => {
            this.cs.openPopUp(error.error.Message, 'warning');
          }
        );
      }
    });
  }

  getData() {
    let cart = localStorage.getItem('ntlCart');

    if (cart) {
      return JSON.parse(cart);
    } else {
      return [];
    }
  }

  seemore(i: any) {
    if (this.datas[i]['showmore']) {
      this.datas[i]['showmore'] = !this.datas[i]['showmore'];
    } else {
      this.datas[i]['showmore'] = true;
    }
  }

  remove(i: number) {
    this.count--;
    this.datas.splice(i, 1);
    this.cs.removeItem('ntlCart');

    this.cs.setItem('ntlCart', JSON.stringify(this.datas));
    this.getData();
  }
}
