<div
  class="ackSection panelheight "
  style="background: #fff;"
>


  <h2 class="sectionSubHeading secondry">Request History</h2>
  <br>
  <!-- <ng-container *ngIf="data && data.length"> -->
    <div class="tableSection">


      <table
        mat-table
        [dataSource]="data"
        class="acktable mat-elevation-z8"
      >


        <ng-container matColumnDef="requestId">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Request Number
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.requestId}}
          </td>
        </ng-container>


        <ng-container matColumnDef="title">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Title
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.title}}
          </td>
        </ng-container>
        <ng-container matColumnDef="author">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Author
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.author}}
          </td>
        </ng-container>


        <ng-container matColumnDef="format">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Format
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.format}}
          </td>
        </ng-container>


        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.status}}
          </td>
        </ng-container>


        <ng-container matColumnDef="requesteddate">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Requested Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{element.requestDate | date:'dd-MM-yyyy'}}
          </td>
        </ng-container>




        <tbody>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns;"
          ></tr>
        </tbody>
      </table>
    </div>


    <mat-paginator
      *ngIf="obs && obs._value.length"
      class="userPaginator"
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  <!-- </ng-container> -->
  <ng-container *ngIf="data && !data.length">
    <div class="flexCenter" [ngStyle]="{'margin-top':!data.length?'2%':'0%'}">No History Found</div>
  </ng-container>
  <!-- <div style="margin-top:20px;display: flex; align-items: center;justify-content: end;">
        <button onclick="window.print()" mat-button class="borderButton">Print &nbsp;

            <mat-icon matSuffix>print</mat-icon>
        </button>
    </div> -->
    <div style="margin-top:10px; display:flex; align-items:center; justify-content:end;">
      <!-- Download CSV Button -->
      <button  mat-stroked-button class="primaryButton" [disabled]="length==0" [ngStyle]="{'color':length==0?'#00000042':'black'}" (click)="getExportCsv()"
      style="box-shadow: 0 4px #bcbbb8;
      background: #f3b201; font-weight:450; margin-right: 10px;">
          Download CSV
          <mat-icon class="buttonIcons" color="#515151">arrow_downward</mat-icon>
      </button>
  </div>


</div>
