import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { EMPTY, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RemoteGridApi } from './remote-grid-api';

@Directive({
    selector: '[remoteGridBinding]'
})
export class RemoteGridBindingDirective {
    @Input()
    remoteGridBinding: RemoteGridApi | any;

    @Output()
    remoteGridReady = new EventEmitter();

    constructor() { }

    @HostListener('gridReady', ['$event']) gridReady(gridApi: GridApi) {
        this.updateGridApi(gridApi);
    }

    updateGridApi(gridApi: any) {
        if (this.remoteGridBinding.gridUpdate) {

            this.remoteGridBinding.gridUpdate.subscribe((e: boolean) => {
                gridApi.api.setDatasource(this.dataSource);
            })
        } else {
            gridApi.api.setDatasource(this.dataSource);
        }


        this.remoteGridReady.emit(gridApi);
    }

    handleError(err: any) {
        this.remoteGridBinding.getDataError(err);
        return EMPTY;
    }

    dataSource: IDatasource = {
        getRows: (params: IGetRowsParams) => {
            // setTimeout(() => {
            this.remoteGridBinding.checkData.subscribe((e: boolean) => {

                if (e) {

                    this.remoteGridBinding
                        .getData(params)
                        .pipe(
                            tap(({ data, totalRecords }) =>
                                params.successCallback(data, totalRecords)
                            ),
                            catchError(err => this.handleError(err))
                        )
                        .subscribe();
                }
            })

            // }, 1000);
        }

    };
}
