import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { MSALInstanceFactory } from 'src/app/app.module';
import { ApiService } from 'src/app/services/api.service';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
  private _router: Subscription | any;

  mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  url: string | any;
  sidePanelOpened: any;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  @ViewChild('sidemenu')
  sidemenu: any;
  @ViewChild(PerfectScrollbarDirective)
  directiveScroll: PerfectScrollbarDirective | any;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    // private _element: ElementRef,
    private router: Router,
    zone: NgZone,
    private api: ApiService
  ) {


    // if (MSALInstanceFactory().getActiveAccount() || localStorage.getItem('currentUser')) {
    //   this.api.get('User/GetLoginUserDetails').subscribe((details: any) => {
    //     localStorage.setItem('currentUser', details.userRole)
    //     let user = details.userRole
    //     if (user == 'Stdnt' || user == 'Staff') {

    //     } else {
    //       this.router.navigate(['/admin']);
    //     }
    //   })
    // }
    this.mediaMatcher.addListener(mql =>
      zone.run(() => {
        this.mediaMatcher = <any>mql;
      })
    );
  }

  ngOnInit(): void {
    this.url = this.router.url;

    // this._router = this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     document.querySelector(
    //       '.app-inner > .mat-drawer-content > div'
    //     ).scrollTop = 0;
    //     this.url = event.url;
    //     this.runOnRouteChange();
    //   });
  }



  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }

    this.updatePS();
  }

  receiveOptions($event: any): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages' ||
      this.url === '/apps/calendar' ||
      this.url === '/apps/media' ||
      this.url === '/maps/leaflet' ||
      this.url === '/taskboard'
    ) {
      return true;
    } else {
      return this.mediaMatcher.matches;
    }
  }

  menuMouseOver(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (this.mediaMatcher.matches && this.options.collapsed) {
      this.sidemenu.mode = 'side';
    }
  }

  updatePS(): void {
    if (!this.mediaMatcher.matches && !this.options.compact) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }
}