import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { userHeaderComponent } from './header/header.component';
import { MaterialModule } from 'src/app/material/material.module';
import { UserRoutingModule } from './user-routing.module';
import { ListingComponent } from './listing/listing.component';
import { UserLayoutComponent } from './user-layout.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/core/shared.module';
import { FiltersComponent } from './filters/filters.component';
import { CartComponent } from './cart/cart.component';
import { UserpopupComponent } from './userpopup/userpopup.component';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { RequesthistoryComponent } from './requesthistory/requesthistory.component';
import { PopupComponent } from './popup/popup.component';



@NgModule({
  declarations: [UserLayoutComponent, userHeaderComponent, ListingComponent, FiltersComponent, CartComponent, UserpopupComponent, PopupComponent, AcknowledgementComponent, RequesthistoryComponent,],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UserRoutingModule,
    MaterialModule
  ]
})
export class UserLayoutModule { }
