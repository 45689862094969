<div id="ackSection" class="ackSection forPrint">
    <h2 class="sectionSubHeading secondry">Acknowledgement for New Titles Selected</h2>
    <p class="" style="font-size: 13px;color: black;">For title(s) selected for reservation(s), you will receive an email notification when the title is available for you to borrow.</p>

    <!-- <h2 class="sectionSubHeading secondry">Notified when copy arrives : No</h2> -->

    <!-- <h2 class="sectionSubHeading secondry">Requestor : {{username}}</h2> -->
    <h2 class="sectionSubHeading secondry">Date : {{date}}</h2>
    <br>
    <table mat-table [dataSource]="data" class="acktable mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <!-- <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="requestId">
            <th mat-header-cell *matHeaderCellDef> Request Number </th>
            <td mat-cell *matCellDef="let element"> {{element.requestId}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>
        <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef>Author </th>
            <td mat-cell *matCellDef="let element"> {{element.author}} </td>
        </ng-container>
        <!-- 
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="requestDate">
            <th mat-header-cell *matHeaderCellDef> Request Date </th>
            <td mat-cell *matCellDef="let element"> {{element.requestDate}} </td>
        </ng-container> -->

        <ng-container matColumnDef="format">
            <th mat-header-cell *matHeaderCellDef> Format </th>
            <td mat-cell *matCellDef="let element"> {{element.format}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div style="margin-top:20px;display: flex; align-items: center;justify-content: end;">
        <button onclick="window.print()" mat-button class="hidewhenprint borderButton">Print &nbsp;

            <mat-icon matSuffix>print</mat-icon>
        </button>
    </div>

</div>