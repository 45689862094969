import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit, OnDestroy {

  data: any
  date = moment().format('DD-MM-YYYY');
  displayedColumns: string[] = ['requestId', 'title', 'author', 'format'];
  username: any;
  constructor(public cs: CommonService) { }
  ngOnDestroy(): void {
    this.cs.removeItem('acknowledgement')
  }

  ngOnInit(): void {
    let ack: any = localStorage.getItem('acknowledgement');
    this.username = localStorage.getItem('username');

    this.data = JSON.parse(ack)
  }


}
