import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective,
} from './core/menu-accordion';
import { AgGridModule } from 'ag-grid-angular';
import { AuthService } from './auth/guards/auth.service';

import { adminLayoutComponent } from './components/adminLayout/admin-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AppInsightService } from './services/appInsight.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ManageLiveTitlesComponent } from './components/adminLayout/manage-live-titles/manage-live-titles.component';
import { SidemenuComponent } from './components/adminLayout/sidemenu/sidemenu.component';
import { DashboardComponent } from './components/adminLayout/dashboard/dashboard.component';
import { HeaderComponent } from './components/adminLayout/header/header.component';
import { ManagedraftComponent } from './components/adminLayout/managedraft/managedraft.component';
import { UserLayoutModule } from './components/user-layout/user-layout.module';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
} from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { ConfigurationComponent } from './components/adminLayout/configuration/configuration.component';
import { SharedModule } from './core/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserMenuComponent } from './components/adminLayout/user-menu/user-menu.component';
import { AddnewUserComponent } from './components/adminLayout/user-menu/addnew-user/addnew-user.component';
import { NgChartsModule } from 'ng2-charts';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { MatChipsModule } from '@angular/material/chips';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { environment } from 'src/environments/environment';
import { AdminRoutingModule } from './components/adminLayout/admin-routing.module';
import { AppAdminRoutingModule } from './app-admin-routing.module';
import { ApiService } from './services/api.service';
import { ErrorHandlerService } from './services/appInsight-handler.service';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: '861bb7c8-9cd5-4ffe-ad0f-7a40a4daf8fc',
//       authority:
//         'https://login.microsoftonline.com/7604ff02-abd8-45db-8cac-550054323fc9',
//       redirectUri: '/',
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage,
//       storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
//     },
//   });
// }


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, any>();

  // protectedResourceMap.set('/SchoolDepartmentAllList', null);
  // protectedResourceMap.set('/BookFormatAllList', null);
  protectedResourceMap.set('/SubmitRequest', protectedResources.apiconfig.scopes);
  protectedResourceMap.set('/GetAllRequestHistory', protectedResources.apiconfig.scopes);
  protectedResourceMap.set('/GetDuplicateRequest', protectedResources.apiconfig.scopes);
  protectedResourceMap.set('UserPortal', null);
  protectedResourceMap.set('/admin', protectedResources.apiconfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
    loginFailedRoute: '/'
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    MaterialModule,
    environment.admin ? AppAdminRoutingModule : AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    UserLayoutModule,
    AgGridModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatChipsModule

    // chartmodule


  ],
  exports: [],
  providers: [

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    { provide: ErrorHandler, useClass: ErrorHandlerService },

    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AppInsightService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [],
  schemas: []
})
export class AppModule {

}
