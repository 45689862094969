<!-- <ngx-spinner bdColor="rgba(172,194,196,0.8)" size="default" color="#2196f3" type="square-jelly-box"></ngx-spinner> -->
<!-- [dir]="options.dir" -->
<div class="app mat-typography"
    [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
    <!-- <ngx-loading-bar color="#009688"></ngx-loading-bar> -->
    <!-- <app-header (toggleSidenav)="sidemenu.toggle()" (toggleNotificationSidenav)="notifications.toggle()"></app-header> -->
    <app-userheader></app-userheader>


    <mat-sidenav-container class="userapp-inner">
        <!-- <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'"
            [opened]="!isOver()" (openedStart)="sidePanelOpened = true" (closedStart)="sidePanelOpened = false"
            (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">

        </mat-sidenav> -->
        <!-- <mat-sidenav #notifications position="end" class="chat-panel" mode="over" opened="false"> -->
        <!-- <app-notification></app-notification> -->
        <!-- </mat-sidenav> -->
        <!-- <div [perfectScrollbar]="config" [disabled]="mediaMatcher.matches"> -->
        <div class="userContent">
            <router-outlet></router-outlet>
        </div>
        <!-- </div> -->
    </mat-sidenav-container>

    <!-- Demo Purposes Only -->
    <!-- <app-options (messageEvent)="receiveOptions($event)"></app-options> -->
    <!-- /Demo Purposes Only -->
</div>