import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  eBookchecked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  ebookdisabled = false;
  loadformat = false;
  formats: any[] = [];
  schools: any[] = [];
  expended: boolean = true;
  @Output() searchValue = new EventEmitter();

  formatform: FormGroup = new FormGroup({});
  schoolsForm: FormGroup = new FormGroup({});

  constructor(private apiService: ApiService, public cs: CommonService, private fb: FormBuilder) { }

  ngOnInit(): void {

    var mql = window.matchMedia('(min-width: 768px)');
    this.expended = mql.matches;
    mql.addListener(this.mqChange);

    this.apiService.userget('UserPortal/BookFormatAllList').subscribe(
      (res: any) => {
        this.formats = res
        this.createFormGroups(res, 'format')

      },
      (err: HttpErrorResponse) => {
        this.cs.openPopUp(err.error.Message, 'warning')
        // this.formats = [{ name: 'name' }, { name: 'name' }]
      }
    )


    this.apiService.userget('UserPortal/SchoolDepartmentAllList').subscribe(
      (res: any) => {
        this.schools = res
        this.createFormGroups(res, 'school')

      },
      (err: HttpErrorResponse) => {
        this.cs.openPopUp(err.error.Message, 'warning')
        // this.schools = [{ name: 'name' }, { name: 'name' }]

      }
    )
  }

  mqChange = (e: any) => {
    this.expended = e.matches;
  }
  check() {
    let School = this.schoolsForm.value
    let format = this.formatform.value
    // School = Object.keys(School).map(function (k) { return School[k] }).join(",");
    // format = Object.keys(format).map(function (k) { return format[k] }).join(",");


    this.searchValue.emit({ school: School, format: format })
  }

  createFormGroups(data: any, type: any) {
    if (type == 'format') {
      data.forEach((e: any) => {
        let control = new FormControl(false)

        this.formatform.addControl(e.name, control)
      });
    } else {
      data.forEach((e: any) => {
        let control = new FormControl(false)

        this.schoolsForm.addControl(e.code, control)
      });
    }

    // this.formatform = this.fb.group({
    //   formats: [null]
    // })
    setTimeout(() => {
      this.loadformat = true;

    }, 100);


  }

}
