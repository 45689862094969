<!-- <span>Do you want to</span>
<h2 mat-dialog-title class="heading">Purge Listing Live</h2>
<div mat-dialog-content></div>
<div mat-dialog-actions [align]="'center'">

</div> -->
<div class="popupWrapper userPopup" style="display: flex;
align-items: flex-start;
justify-content: flex-end;">
    <div style="padding: 81px 17px 0px;">
        <h1 style="font-size:22px;color:#4d4d4d;font-weight: 600;letter-spacing: 0.7px;">Do you want to submit
            selected titles</h1>
        <div>
            <button [mat-dialog-close]="true" cdkFocusInitial mat-button class="primaryButton"
                style="padding: 0px 25px; border-radius: 5px;margin-right: 15px;  box-shadow: 0 4px #bcbbb8; background: #f3b201; font-weight:450;color: black;">OK</button>
            <button [mat-dialog-close]="false" mat-button class="borderButton"
                style="padding: 0px 25px; border-radius: 5px; box-shadow: 0px 4px #bfb8b8;font-weight: 450; color: black !important;">Cancel</button>
        </div>
    </div>
    <!-- <div class="buttonSection"> -->
    <!-- <button class="primaryButton">OK</button> -->
    <!-- </div> -->
</div>