import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from "@angular/common/http";
import { finalize, Observable } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";

import { MsalGuard, MsalService } from "@azure/msal-angular";
// import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    currUser: any;

    // private jwtAuth: JwtAuthService
    constructor(private loader: LoaderService, private msal: MsalService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // let user: any = JSON.stringify(localStorage.getItem('token'));
        // let user = JSON.stringify({ token: 'token' })
        // this.currUser = JSON.parse(user);
        // var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();
        // var token = this.currUser.token
        this.loader.show();
        var changedReq;

        // if (token) {

        // changedReq = req.clone({
        // setHeaders: {
        // 'Access-Control-Allow-Origin': '*',
        // Authorization: `Bearer ${token}`
        // },
        // });

        // } else {

        changedReq = req;

        // }
        // return next.handle(changedReq);

        return next.handle(changedReq).pipe(
            finalize(() => this.loader.hide())
        );
    }
}
