import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
const isIframe = window !== window.parent && !window.opener;
const routes: Routes = [
  // {
  //   path: 'admin',
  //   canActivate: [MsalGuard],
  //   loadChildren: () =>
  //     import('./components/adminLayout/admin.module').then(
  //       (m) => m.AdminModule
  //     ),
  // },

  {
    // Needed for hash routing
    path: 'error',
    loadChildren: () =>
      import('./components/user-layout/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },
  {
    // Needed for hash routing
    path: 'state',
    loadChildren: () =>
      import('./components/user-layout/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },
  {
    // Needed for hash routing
    path: 'code',
    loadChildren: () =>
      import('./components/user-layout/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./components/user-layout/user-layout.module').then(
        (m) => m.UserLayoutModule
      ),
  },

  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
