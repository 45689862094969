import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightService } from './appInsight.service';


@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor(private appinsight: AppInsightService) {
        super();
    }

    override handleError(error: Error): void {
        this.appinsight.logException(error); // Manually log exception
    }

}