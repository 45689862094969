import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { CartComponent } from './cart/cart.component';
import { ListingComponent } from './listing/listing.component';
import { RequesthistoryComponent } from './requesthistory/requesthistory.component';
import { UserLayoutComponent } from './user-layout.component';

const routes: Routes = [
    {
        path: '',
        component: UserLayoutComponent,
        children: [
            
           
            {
                path: 'cart',
                canActivate: [MsalGuard],
                component: CartComponent,
            },
            {
                path: 'acknowledgement',
                canActivate: [MsalGuard],
                component: AcknowledgementComponent,
            },
            {
                path: 'history',
                canActivate: [MsalGuard],
                component: RequesthistoryComponent,
            },
            {
                path: '',
                component: ListingComponent,
            },
            {
                path: ':url',
                component: ListingComponent,
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule { }
