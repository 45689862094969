<div class="filtersSection">
  <div class="searchSection">
    <span class="headSection">
      <p
        class="heading"
        style="margin:0;"
      >
        Refine your search
      </p>
      <mat-icon>tune</mat-icon>
    </span>
  </div>
  <hr>
  <div class="searchSection">
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="expended"
        style="background: transparent;box-shadow: none;"
      >
        <mat-expansion-panel-header class="expensionHeader">
          <mat-panel-title>Format</mat-panel-title>



        </mat-expansion-panel-header>
        <span
          [formGroup]="formatform"
          *ngIf="loadformat"
          style="display: flex; flex-direction: column;"
        >
          <!-- <form [formGroup]="formatform"> -->
          <mat-checkbox
            (change)="check()"
            [formControlName]="format.name"
            *ngFor="let format of formats"
            [value]="format.name"
            [(indeterminate)]="indeterminate"
            [labelPosition]="labelPosition"
          >
            {{format.name}}
          </mat-checkbox>
          <!-- </form> -->




        </span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <hr>
  <div class="searchSection">
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="expended"
        style="background: transparent;box-shadow: none;"
      >
        <mat-expansion-panel-header class="expensionHeader">
          <mat-panel-title>School</mat-panel-title>



        </mat-expansion-panel-header>
        <span
          [formGroup]="schoolsForm"
          style="display: flex; flex-direction: column;"
        >
          <mat-checkbox
            (change)="check()"
            [formControlName]="school.code"
            *ngFor="let school of schools"
            [(indeterminate)]="indeterminate"
            [labelPosition]="labelPosition"
          >
            {{school.code}}
          </mat-checkbox>
        </span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
