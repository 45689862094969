<h2 mat-dialog-title class="heading">The following title(s) were selected by you previously, and removed from the Cart.</h2>


<div style="overflow-y: auto;max-height: 21vh;background-color: #f2f3f8;padding: 5px 8px;">
    <mat-card style="padding: 8px;margin:10px 0px; display: flex;justify-content: flex-start;" class="cardLayout"
        *ngFor="let data of data;let i = index">
        <img style="border-radius: 13px;    width: 65px;
        padding: 10px;
    " onerror="this.onerror=null;this.src='assets/images/dummy.gif';" [src]="data.imgURL">
        <div class="content" style="padding-left:15px;width: 100%;">
            <mat-card-title style="font-size: 18px;text-align: start; display: flex;justify-content: space-between;">
                {{data.name}}
                <span style="width: 10vw;"></span>
                <span>Status</span>
            </mat-card-title>
            <mat-card-subtitle style="margin-bottom: 8px;display: flex;justify-content: space-between;">
                <span style="display: flex;">
                    <span class="subtitles fadded">by :</span>
                    <span class="authorshort">
                        {{data.author}}
                    </span>
                    <span class="fadded" style="padding: 0px 10px;"> |</span>
                    <span><span class="subtitles fadded">year :</span> {{data.publicationYear}}</span>

                </span>
                <span>
                    {{data.status}}
                </span>
            </mat-card-subtitle>
            <hr>
        </div>

    </mat-card>
</div>
<!-- <div class="cartpopup dataSection">
    <div class="list-content" style="padding: 20px 0px;">
        <div class="BookdataWrapper " style="padding: 0;">
            <mat-card class="cardLayout" *ngFor="let data of data;let i = index">
                <div class="imgSection">
                    <img style="border-radius: 13px;" onerror="this.onerror=null;this.src='assets/images/dummy.gif';"
                        [src]="data.imgURL">
                </div>
                <div class="dataSection">
                    <span class="content">
                        <mat-card-title>{{data.name}}</mat-card-title>
                        <mat-card-subtitle>
                            <span style="display: flex;">
                                <span class="subtitles fadded">by :</span>
                                <span class="authorshort">
                                    {{data.author}}
                                </span>
                            </span>
                            <span class="fadded" style="padding: 0px 10px;"> |</span>
                            <span *ngIf="data.bookFormatName&&data.bookFormatName!=''"><span
                                    class="subtitles fadded">type
                                    :</span>{{data.bookFormatName}}</span>
                            <span *ngIf="data.bookFormatName&&data.bookFormatName!=''" class="fadded"
                                style="padding: 0px 10px;"> |</span>
                            <span><span class="subtitles fadded">year :</span> {{data.publicationYear}}</span>
                        </mat-card-subtitle>
                        <hr>


                    </span>
                </div>


            </mat-card>
        </div>
    </div>
</div> -->
<div mat-dialog-actions [align]="'center'">

    <button style="padding: 0px 25px;" mat-dialog-close mat-stroked-button class="primaryButton" style="box-shadow: 0 4px #bcbbb8;background: #f3b201; font-weight:450;color: black;">
        Ok
    </button>
</div>