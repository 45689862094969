<div
  *ngIf="datas && datas.length"
  class="cartMain"
>
  <h2 class="mainHeading secondry">
    Confirm your selection of titles for Library Purchase
  </h2>



  <div
    class="list-content"
    style="padding: 20px 0px;"
  >
    <div
      class="BookdataWrapper "
      style="width:57vw;padding: 0;"
    >
      <mat-card
        class="cardLayout"
        *ngFor="let data of datas;let i = index"
      >
        <div class="imgSection">
          <!-- <img height="160px" style="border-radius: 13px;" [src]="data.imgURL"> -->
          <img
            height="160px"
            style="border-radius: 13px;"
            onerror="this.onerror=null;this.src='assets/images/dummy.gif';"
            [src]="data.imgURL"
          >
        </div>
        <div class="dataSection">
          <span class="content">
            <mat-card-title>{{data.name}}</mat-card-title>
            <mat-card-subtitle>
              <span style="display: flex;">
                <span class="subtitles fadded">by :</span>
                <span class="authorshort">{{data.author}}</span>
              </span>
              <span
                class="fadded"
                style="padding: 0px 10px;"
              >
                |
              </span>
              <span *ngIf="data.bookFormatName&&data.bookFormatName!=''">
                <span class="subtitles fadded">
                  type
                                    :
                </span>
                {{data.bookFormatName}}
              </span>
              <span
                *ngIf="data.bookFormatName&&data.bookFormatName!=''"
                class="fadded"
                style="padding: 0px 10px;"
              >
                |
              </span>
              <span>
                <span class="subtitles fadded">year :</span>
                {{data.publicationYear}}
              </span>
            </mat-card-subtitle>
            <hr>



            <div class="textContent">
              <p
                [ngClass]="data.showmore?'moreData':'shortData'"
                [innerHTML]="convertData(data.summary)"
              ></p>
              <div style="display:flex;justify-content:space-between;align-items:center;">
                <p
                  style="cursor: pointer;"
                  (click)="seemore(i)"
                  class="fadded"
                >
                  {{data.showmore?'See Less':'See More'}}
                </p>
                <mat-checkbox
                  (change)="makereserve(i)"
                  class="reserveCheckbox"
                  [checked]="data.reserveTitle?data.reserveTitle:false"
                  [(indeterminate)]="indeterminate"
                  [labelPosition]="labelPosition"
                >
                  Reserve Title
                </mat-checkbox>
              </div>
              <div  style="display:flex;justify-content:space-between;align-items:center;">
                <textarea (keyup)="getRemarkValue($event, i)" [value]="getRemark(i) || ''" rows="3" cols="85" maxlength="250" placeholder="Remark"></textarea>
              </div>
            </div>
          </span>
        </div>
        <div class="addButton">
          <button
            (click)="remove(i)"
            mat-fab
            color="warn"
            class="closeButton"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>



      </mat-card>
    </div>
    <div
      class="detailSection"
      style="margin-left:25px;flex: 1;"
    >
      <mat-card class="cardLayout">
        <!-- <div class="imgSection">
                <img height="160px" style="border-radius: 13px;" [src]="data.img">
            </div> -->
        <div
          class="dataSection"
          style="width: 100%;    padding-right: 20px;                "
        >
          <span
            class="content"
            style="width: 100%;"
          >
            <!-- <mat-card-title>{{data.title}}</mat-card-title> -->
            <!-- <mat-card-subtitle style="width: 100%;"> -->
            <p style="margin-bottom: 5px;">You have</p>
            <!-- </mat-card-subtitle> -->



            <!-- <div class="textContent"> -->
            <h2 style="margin-bottom: 8px;">{{count}} Titles(s)</h2>
            <div
              class="flexCenter"
              style="justify-content: space-between;  padding-bottom: 10px;"
            >
              <button
                (click)="submit()"
                mat-button
                style="padding: 0px 21px; border-radius: 5px; box-shadow: 0 4px #bcbbb8; background: #f3b201; font-weight:450;color: black;"
                class="primaryButton"
              >
                Submit
              </button>
              <button
                (click)="clearAll()"
                mat-button
                style="padding: 0px 21px; border-radius: 5px; box-shadow: 0 4px #bcbbb8 ; font-weight:450;color: black;"
                class="secondryButton"
              >
                Clear
                                All
              </button>
              <button
                mat-button
                [routerLink]="''"
                style="padding: 0px 21px; border-radius: 5px; box-shadow: 0px 4px #bfb8b8;font-weight: 450; color: black !important;"
                class="borderButton"
              >
                Add
                                More Titles
              </button>
            </div>
            <!-- <hr>
                         <h2 class="secondry" style="margin:10px 0px;font-weight: 400;">Do you wish to be Notified</h2> -->
            <!-- </div> -->
            <!-- <p>{{data.textContent}}</p> -->
            <!-- <p class="fadded">
                            See More
                        </p> -->
            <!-- </div> -->
          </span>
        </div>
      </mat-card>
    </div>
  </div>



</div>
<div
  class="flexCenter"
  style="height: 60vh;"
  *ngIf="datas && !datas.length"
>
  No Title were Added
</div>
