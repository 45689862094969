import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from '../material/material.module';
import { RemoteGridBindingDirective } from './remote-grid.directive';

@NgModule({
    declarations: [
        RemoteGridBindingDirective,
    ],
    imports: [MaterialModule],
    exports: [
        RemoteGridBindingDirective,
        MaterialModule
    ]
})
export class SharedModule { }