import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// import { AppInsights } from "applicationinsights-js";
import { environment } from "src/environments/environment";

@Injectable()
export class AppInsightService {
    appInsights: ApplicationInsights;
    routerSubscription: any;
    currentRoute: any;
    constructor(private router: Router, private cs: CommonService, private route: ActivatedRoute) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.instrumentationKey,
                enableAutoRouteTracking: true,
                enableDebug: true
            }
        });
        // this.appInsights.loadAppInsights()
        // track route
        this.routerSubscription = this.router.events
            .subscribe((event: any) => {
                if (event instanceof ResolveEnd) {
                    const activatedComponent = this.getActivatedComponent(event.state.root);
                    if (activatedComponent) {
                        this.currentRoute = ` ${this.getRouteTemplate(event.state.root)}`;
                        // this.logTrace(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, { email: this.cs.currentUser['email'], event: event.urlAfterRedirects });
                    }

                }
            });
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    getRouteTemplate(snapshot: any):any {
        let path= '';
        if (snapshot.firstChild) {
            return path + snapshot.firstChild?._routerState.url;
        }
        return path;
    }

    logPageView(name?: string, url?: string) {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    // logTrace(message: string, properties?: { [key: string]: any }) {
    //     this.appInsights.trackTrace({ message: message }, properties);
    // }
}